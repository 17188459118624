import React from 'react';
import { Form, Label, Radio } from 'semantic-ui-react';
import classnames from 'classnames';
import { ProductOffer } from '@pvolve/sdk/src/app/subscriptions/ProductGroup';
import { isMonthly } from '@pvolve/sdk/src/app/modules/subscriptions/utils';

import * as FormStyles from 'src/styles/upgrade-plan.module.scss';

interface RadioProps {
    promoApplied?: boolean;
    hideSavingsTag?: boolean;
    selectedPlan?: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    planData: ProductOffer;
}

const RadioButton = ({
    promoApplied = false,
    hideSavingsTag = false,
    selectedPlan,
    setFieldValue,
    planData,
}: RadioProps) => {
    const { product, savings, discountedSavings, hasDiscount } = planData;
    const { interval, interval_type } = product.subscription_details;
    const checked = selectedPlan === product.sku;
    const discountTagClassnames = classnames('upper', FormStyles.discountTag);
    const fieldClassnames = classnames({ active: checked });

    const handleChange = (sku: string) => () => setFieldValue('selectedPlan', sku);

    let savingsTag;
    if (!hideSavingsTag) {
        if (savings && discountedSavings && !promoApplied) {
            savingsTag = (
                <>
                    <span>Save </span>
                    <span className={FormStyles.strikethrough}>{savings}%</span>{' '}
                    <span>{discountedSavings}%</span>
                </>
            );
        } else if (!promoApplied && (savings || discountedSavings)) {
            savingsTag = <span>Save {savings || discountedSavings}%</span>;
        }
    }

    return (
        <Form.Field className={fieldClassnames}>
            <div className={FormStyles.cardHeaderContainer} style={{ display: 'flex' }}>
                {!!savingsTag && <h6 className={discountTagClassnames}>{savingsTag}</h6>}
                {checked && <p className={FormStyles.currentTag}>Current Plan</p>}
            </div>
            <Radio
                label={product.name}
                name="plan"
                value={product.sku}
                checked={checked}
                onChange={handleChange(product.sku)}
            />

            <div className={FormStyles.planDetails}>
                {hasDiscount ? (
                    <h6 className="upper bold margin--0">
                        <span className={FormStyles.strikethrough}>${planData.monthlyPrice}</span>{' '}
                        <span className="color-pv-black">${planData.discountedMonthlyPrice}</span> a
                        month
                    </h6>
                ) : (
                    <h6 className="upper bold margin--0">${planData.monthlyPrice} a month</h6>
                )}
                {hasDiscount && (
                    <>
                        <p className="color-pv-black margin--0">
                            billed {planData.discountedBillingPrice} for first{' '}
                            {interval === 1 ? interval_type : `${interval} ${interval_type}s`}
                        </p>
                        <p className="color-pv-black margin--0">
                            promo price for 1 billing cycle then
                        </p>
                    </>
                )}
                {(hasDiscount || !isMonthly(product)) && (
                    <p className="p3">billed {planData.billingPrice}</p>
                )}
            </div>
        </Form.Field>
    );
};

export default RadioButton;
