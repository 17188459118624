import React from 'react';
import { Grid } from 'semantic-ui-react';
import classnames from 'classnames';
import { useBreakpoints, Directions, Breakpoints } from 'src/utils/breakpoint-utils';

interface GridLayoutProps {
    leftContent: React.ReactNode;
    rightContent: React.ReactNode;
}

const GridLayout = ({ leftContent, rightContent }: GridLayoutProps) => {
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);

    const columnClassnames = classnames({
        'margin-top--small': isMobile,
    });

    return (
        <Grid.Row verticalAlign="bottom" columns={isMobile ? 1 : 2}>
            <Grid.Column>{leftContent}</Grid.Column>
            <Grid.Column className={columnClassnames} textAlign={isMobile ? 'left' : 'right'}>
                {rightContent}
            </Grid.Column>
        </Grid.Row>
    );
};

export default GridLayout;
