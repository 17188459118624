import React, { useEffect, useState } from 'react';
import Pvolve from '@pvolve/sdk';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import { notifyBugsnagError } from 'src/utils/bugsnag';
import { navigate } from 'gatsby';
import { isNull } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { WindowLocation } from '@reach/router';
import { Button, Grid } from 'semantic-ui-react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Actions from 'src/state/root-actions';
import Layout from 'src/components/layout/Layout';
import withLocation from 'src/components/withLocation';
import { Banner, StripeCardElement } from 'src/components/shared';
import { SquareCloseButton } from 'src/components/shared';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';

import * as updatePaymentStyles from 'src/styles/update-payment.module.scss';

const connector = connect(
    (state: IState) => ({
        paymentMethod: PvolveSelectors.subscriptions.paymentMethod(state),
    }),
    {
        loadPaymentMethods: () => Actions.subscriptions.loadPaymentMethods.trigger(),
    }
);

interface UpdatePaymentProps extends ConnectedProps<typeof connector> {
    location: WindowLocation;
}

const UpdatePaymentMethod = ({
    location,
    paymentMethod,
    loadPaymentMethods,
}: UpdatePaymentProps) => {
    const [stripeClientSecret, setStripeClientSecret] = useState('');
    const [stripeProcessing, setStripeProcessing] = useState(false);
    const [internalServerError, setInternalServerError] = useState(false);
    const [validCard, setValidCard] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    const stripe = useStripe();
    const elements = useElements();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const stripeInit = async () => {
            // Create SetupIntent over Stripe API to get client secret
            try {
                const {
                    response_code,
                    data: paymentSetup,
                } = await Pvolve.api.commerce.paymentSetup();

                if (response_code !== 0 || !paymentSetup?.client_secret) {
                    if (!internalServerError) {
                        setInternalServerError(true);
                    }

                    return;
                }

                setStripeClientSecret(paymentSetup.client_secret);
            } catch (error) {
                notifyBugsnagError(error);
                // The API call returned a non-200 response
                if (!internalServerError) {
                    setInternalServerError(true);
                }
            }
        };

        stripeInit();
    }, []);

    const handleCardElementChange = (e: StripeCardElementChangeEvent): void => {
        const isValid = e.complete && !e.error;

        if (validCard !== isValid) {
            setValidCard(isValid);
        }
    };

    const onSave = async (e: React.MouseEvent<HTMLButtonElement>) => {
        setStripeProcessing(true);

        const stripeCardElement = elements?.getElement(CardElement);

        const { setupIntent, error } = await stripe?.confirmCardSetup(stripeClientSecret, {
            payment_method: {
                card: stripeCardElement,
            },
        });

        if (error) {
            setSubmitError(error);
            setStripeProcessing(false);
            return;
        } else {
            if (!isNull(submitError)) {
                setSubmitError(null);
            }
        }

        if (setupIntent?.payment_method) {
            const response = await Pvolve.api.commerce.setDefaultPayment(
                setupIntent.payment_method
            );

            if (response.response_code > 0) {
                setStripeProcessing(false);
                setSubmitError(response.message);
                return;
            }

            // update redux with new payment methods
            loadPaymentMethods();

            navigate('/account/subscription', {
                state: {
                    successMessage: 'Payment successfully updated.',
                },
            });
        }
    };

    const pageContext = {
        theme: {
            layout: 'plain',
        },
    };

    const isReady = stripe && elements && stripeClientSecret;

    let brand = '';
    let last_four = '';
    let exp_month = '';
    let exp_year = '';

    if (paymentMethod) {
        const { credit_card } = paymentMethod;
        brand = credit_card?.brand;
        last_four = credit_card?.last_four;
        exp_month = credit_card?.exp_month;
        exp_year = credit_card?.exp_year;
    }

    const { widths } = SUI_GRID_CENTERED;

    return (
        isReady && (
            <Layout pageContext={pageContext} location={location}>
                <div className={updatePaymentStyles.wrapper}>
                    <SquareCloseButton />
                    <Grid container centered>
                        <Grid.Row>
                            <Grid.Column
                                mobile={widths.mobile}
                                tablet={widths.tablet}
                                computer={widths.computer}
                            >
                                <h5 className="bold upper">Payment</h5>
                            </Grid.Column>
                        </Grid.Row>
                        {submitError && (
                            <Grid.Row>
                                <Grid.Column
                                    mobile={widths.mobile}
                                    tablet={widths.tablet}
                                    computer={widths.computer}
                                >
                                    <Banner type="ERROR" withIcon>
                                        {submitError}
                                    </Banner>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                        <Grid.Row>
                            <Grid.Column
                                mobile={widths.mobile}
                                tablet={widths.tablet}
                                computer={widths.computer}
                            >
                                <p className="p2 bold accent margin--0">card on file</p>
                                <p>{`${brand} ****${last_four} exp ${exp_month}/${exp_year}`}</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                mobile={widths.mobile}
                                tablet={widths.tablet}
                                computer={widths.computer}
                            >
                                <StripeCardElement onChange={handleCardElementChange} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column
                                mobile={widths.mobile}
                                tablet={widths.tablet}
                                computer={widths.computer}
                            >
                                <Button
                                    onClick={onSave}
                                    disabled={!validCard}
                                    loading={stripeProcessing}
                                >
                                    Save Payment
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Layout>
        )
    );
};

export default connector(withLocation(UpdatePaymentMethod));
