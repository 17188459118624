import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import SwitchToggle from 'src/components/ui/SwitchToggle';

import Actions from 'src/state/root-actions';
import AccountPageWrap from 'src/components/account/AccountPageWrap';
import Selectors from 'src/state/root-selectors';

const connector = connect((state) => ({
    optOutState: state.account.getAllAttrs?.data?.email?.object?.opt_out,
    userEmail: Selectors.account.userAttributes(state)?.object?.email,
}));

const AccountCommunications = ({ dispatch, path, optOutState, userEmail }) => {
    const [emailAttrsVersion, setEmailAttrsVersion] = useState();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const onSuccess = ({ version, object: { opt_out } }) => {
            setEmailAttrsVersion(version);
        };

        dispatch(Actions.account.getEmailAttrs.trigger({ onSuccess }));
    }, []);

    const toggleCommunicationPref = (value) => {
        const newVersion = emailAttrsVersion + 1;
        const newCommunicationPref = !optOutState;

        /** TODO: what is the proper error messaging here? */
        const onFailure = (message) => console.log(message);
        const onSuccess = () => {
            setEmailAttrsVersion(newVersion);
            if (userEmail) {
                dispatch(
                    Actions.segment.identify({
                        userId: userEmail,
                        traits: {
                            email: userEmail,
                            email_marketing_opted_in: value ? 'opted_in' : 'unsubscribed',
                        },
                    })
                );
                dispatch(
                    Actions.segment.track.marketingOptedIn({
                        email: userEmail,
                        source_detail: 'communications',
                        source: 'app.pvolve.com',
                        is_email_opt_in: value,
                    })
                );
            }
        };

        dispatch(
            Actions.account.saveEmailAttrs.trigger({
                optOut: newCommunicationPref,
                version: newVersion,
                onFailure,
                onSuccess,
            })
        );
    };

    return (
        <AccountPageWrap path={path} title="Communications" fullWidth>
            <div className="display-row">
                <SwitchToggle
                    active={!optOutState}
                    onChange={toggleCommunicationPref}
                    label="I want to receive emails from Pvolve about news and discounts"
                />
            </div>
        </AccountPageWrap>
    );
};

export default connector(AccountCommunications);
