import React from 'react';
import classnames from 'classnames';
import { Input, Button, Grid } from 'semantic-ui-react';
import { FormikProps } from 'formik';
import { FormValues } from '../UpgradePlan';
import { getErrorMessage } from 'src/utils/form-utils';

import { Icon } from 'src/components/shared';

import * as FormStyles from 'src/styles/upgrade-plan.module.scss';

import { Discount } from '@pvolve/sdk/src/CommerceService';

interface InputProps extends FormikProps<FormValues> {
    appliedPromo?: Discount;
    promoDescription?: string;
    onApplyClick: () => void;
    onRemoveClick: () => void;
    errorState: any;
}

const PromoInput = ({
    values,
    handleChange,
    appliedPromo,
    promoDescription,
    onApplyClick,
    onRemoveClick,
    errorState,
}: InputProps) => {
    const { promoCode, validatedPromoCode } = values;
    const labelClasses = classnames('bold', 'small', FormStyles.promoLabel);

    const onApply = () => {
        onApplyClick && onApplyClick();
    };

    const onRemove = () => {
        onRemoveClick && onRemoveClick();
    };

    return (
        <>
            {!!values.promoCode && (
                <label htmlFor="promoCode" className={labelClasses}>
                    promo code
                </label>
            )}

            <Grid className={'margin--0'}>
                <Grid.Row className={'padding--0'}>
                    <Grid.Column className={'padding--0'} width={11}>
                        <Input
                            name="promoCode"
                            onChange={handleChange}
                            placeholder="promo code"
                            spellcheck={false}
                            value={promoCode}
                            id="promoCode"
                            error={!!errorState?.errorMessage && true}
                        />
                    </Grid.Column>
                    <Grid.Column
                        className={classnames(FormStyles.promoButton, 'padding-horizontal--0')}
                        width={4}
                    >
                        {(errorState?.errorMessage || appliedPromo) &&
                        promoCode == validatedPromoCode ? (
                                <Button onClick={onRemove} size="large">
                                Remove
                                </Button>
                            ) : (
                                <Button onClick={onApply} size="large" disabled={!promoCode} secondary>
                                Apply
                                </Button>
                            )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {errorState?.errorMessage
                ? getErrorMessage(errorState.errorMessage)
                : appliedPromo && (
                    <p className="p3 margin--0 color-pv-black">
                        <Icon name="check circle" /> {promoDescription}
                    </p>
                )}
        </>
    );
};

export default PromoInput;
