import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import withLocation from 'src/components/withLocation';
import Selectors from '@pvolve/sdk/src/app/modules/subscriptions/selectors';
import Actions from 'src/state/root-actions';
import { ContentSelectors, IState } from '@pvolve/sdk/src/redux/selectors';
import { subscriptionEndDate } from '@pvolve/sdk/src/app/modules/subscriptions/utils';
import { WindowLocation } from '@reach/router';

import { ModalWithImage } from 'src/components/shared';
import { SquareCloseButton } from 'src/components/shared';
import CTASection from './cancel-subscription/CTASection';
import ConfirmCancellation from './cancel-subscription/ConfirmCancellation';

import * as Styles from 'src/styles/cancel-subscription.module.scss';
import { find } from 'lodash';

const connector = connect(
    (state: IState) => ({
        subscription: Selectors.activeSubscription(state),
        isSubmitting: Selectors.cancelling(state),
        loading: Selectors.loading(state),
        checkedSubscriptions: Selectors.checkedSubscriptions(state),
    }),
    (dispatch) => ({
        cancel: () => dispatch(Actions.subscriptions.cancelSubscription.trigger()),
        load: () => dispatch(Actions.subscriptions.load.trigger()),
    })
);

interface CancelSubscriptionProps extends ConnectedProps<typeof connector> {
    location: WindowLocation;
}

const CancelSubscription = ({
    subscription,
    cancel,
    load,
    loading,
    isSubmitting,
    checkedSubscriptions,
}: CancelSubscriptionProps) => {
    const dispatch = useDispatch();
    const content = useSelector(ContentSelectors.asset.list);
    const backgroundImg = find(content, (item) => item?.fields?.title === 'cancel-subscription');
    const backgroundImgUrl = backgroundImg?.fields.file.url ?? '';
    const buttonIdConstants = {
        cancelMyMembership: 'cancel-my-membership',
        cancelAnyway: 'cancel-anyway',
    };
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!checkedSubscriptions) {
            load();
        }
    }, []);

    useEffect(() => {
        // Segment Track event for Cancel flow step
        dispatch(Actions.segment.track.appSubscriptionCancelStepViewed());
    }, [dispatch]);

    const [showConfirmCancellation, setConfirmCancellation] = useState(false);
    const onClick = useCallback(
        (event, data?: { datatype: string; id?: string }) => {
            if (data?.id === buttonIdConstants.cancelAnyway) {
                // Segment Track event for Cancellation Submitted
                dispatch(Actions.segment.track.appSubscriptionCancellationSubmitted());
                return cancel();
            }
            // Segment Track event for CTAClicked
            dispatch(
                Actions.segment.track.ctaClicked({
                    cta_name: data?.datatype ?? event.target?.attributes?.datatype?.value,
                    event,
                })
            );
            if (event.target?.id === buttonIdConstants.cancelMyMembership) {
                setConfirmCancellation(true);
                // Segment Track event for Cancel flow step
                dispatch(Actions.segment.track.appSubscriptionCancelStepViewed());
            }
        },
        [dispatch]
    );

    if (!loading && !subscription) {
        navigate('/account/subscription');
        return null;
    }

    const endDate = subscription && subscriptionEndDate(subscription);
    const disabled = isSubmitting || loading;

    return (
        <div className={Styles.cancelSubscriptionPage}>
            <SquareCloseButton />
            <ModalWithImage
                imageUrl={backgroundImgUrl}
                imageAltText="A woman stretching with a P.ball"
            >
                <div className={Styles.cancelSubscriptionWrapper}>
                    <h1 className={Styles.cancelSubscriptionHeader}>WE HATE TO SEE YOU GO!</h1>
                    {showConfirmCancellation ? (
                        <>
                            <ConfirmCancellation
                                endDate={endDate}
                                disabled={disabled}
                                buttonIds={buttonIdConstants}
                                onClick={onClick}
                            ></ConfirmCancellation>
                        </>
                    ) : (
                        <>
                            <CTASection onClick={onClick}></CTASection>
                            <a
                                id={buttonIdConstants.cancelMyMembership}
                                datatype="cancellation-cancel"
                                className={Styles.cancelSubscriptionLink}
                                onClick={onClick}
                            >
                                No thanks, cancel my membership.
                            </a>
                        </>
                    )}
                </div>
            </ModalWithImage>
        </div>
    );
};

export default connector(withLocation(CancelSubscription));
