import React from 'react';
import { Button } from 'semantic-ui-react';
import { connect, ConnectedProps } from 'react-redux';
import pluralize from 'pluralize';
import { Product, Subscription } from '@pvolve/sdk/src/SubscriptionService';
import { Discount } from '@pvolve/sdk/src/CommerceService';
import { pricePerPeriodDescription } from '@pvolve/sdk/src/app/modules/subscriptions/utils';
import { IState } from '@pvolve/sdk/src/redux/selectors';

import Selectors from 'src/state/root-selectors';
import GridLayout from './GridLayout';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';

interface PlanSettingsProps {
    isItunesAccount?: boolean;
    product: Product;
    nextProduct: Product;
    subscription: Subscription;
    nextDiscount: Discount;
}

const connector = connect((state: IState) => ({
    defaultPromo: Selectors.subscriptions.defaultPromo(state),
}));

const PlanSettings = ({
    isItunesAccount = false,
    product,
    nextProduct,
    subscription,
    defaultPromo,
    nextDiscount,
}: PlanSettingsProps & ConnectedProps<typeof connector>) => {
    let nextPrice;
    let continuingPrice = pricePerPeriodDescription(product, true);
    let discount;

    if (subscription.next_renewal && nextProduct) {
        product = nextProduct;
        discount = nextProduct.cost.unit_amount - subscription.next_renewal.amount;
        nextPrice = subscription.next_renewal.amount;
        nextPrice = pricePerPeriodDescription(nextProduct, true, discount);
        if (nextDiscount?.is_permanent) {
            continuingPrice = pricePerPeriodDescription(nextProduct, true, discount);
        } else {
            continuingPrice = pricePerPeriodDescription(nextProduct, true);
        }
    }
    const isExpired = subscription.state === 'expired';
    return (
        <GridLayout
            leftContent={
                <>
                    <h3 className="upper bold">My Plan</h3>

                    {discount && !nextDiscount?.is_permanent ? (
                        <>
                            <p className="p2 margin--0 color-pv-blue">{nextPrice}</p>
                            <p className="p2 margin--0 color-pv-blue">
                                promo price for{' '}
                                {pluralize('billing cycle', nextDiscount?.max_intervals ?? 1, true)}{' '}
                                then
                            </p>
                            <p className="p2">{continuingPrice}</p>
                        </>
                    ) : (
                        <p>{continuingPrice}</p>
                    )}
                </>
            }
            rightContent={
                <>
                    {isItunesAccount ? (
                        <h6 className="upper bold margin--0 color-pv-gray-50">Manage in iTunes</h6>
                    ) : (
                        !isExpired &&
                        !defaultPromo && (
                            <Button as={LinkWithPrevPath} to="/account/subscription/update">
                                Change Plan / Apply Promo
                            </Button>
                        )
                    )}
                </>
            }
        />
    );
};

export default connector(PlanSettings);
