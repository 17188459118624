import React from 'react';
import { Grid, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import classnames from 'classnames';

import AccountPageWrap from 'src/components/account/AccountPageWrap';
import Selectors from 'src/state/root-selectors.js';
import * as accountStyles from 'src/styles/account.module.scss';

import { Banner, LinkWithArrow } from 'src/components/shared';

const headerCell = ({ key, title, align = 'left' }) => (
    <Table.HeaderCell key={key} textAlign={align}>
        {title || key}
    </Table.HeaderCell>
);

const TableSeparator = () => (
    <Table.Row className={accountStyles.hr}>
        <Table.Cell colSpan={5}>
            <span></span>
        </Table.Cell>
    </Table.Row>
);

const BodyRow = ({ sku, amount, quantity, name }) => {
    return (
        <Table.Row key={sku}>
            <Table.Cell width={4}>{name}</Table.Cell>
            <Table.Cell width={3} className="heavy">
                {sku}
            </Table.Cell>
            <Table.Cell width={3}>${amount}</Table.Cell>
            <Table.Cell width={1} textAlign="right">
                {quantity}
            </Table.Cell>
            <Table.Cell width={5}>${amount * quantity}</Table.Cell>
        </Table.Row>
    );
};

const TaxesRow = ({ key, amount }, index) => {
    return (
        <Table.Row key={index}>
            <Table.Cell colSpan={4} textAlign="right">
                {key}
            </Table.Cell>
            <Table.Cell>${amount}</Table.Cell>
        </Table.Row>
    );
};

const tableClassnames = classnames(accountStyles.orderTableContainer, accountStyles.orderDetails);

const OrderDetailsPage = ({ path, details, orderId }) => (
    <AccountPageWrap path={path} title={`Order ${orderId}`} fullWidth>
        <div className={tableClassnames}>
            <LinkWithArrow back to="/account/orders" label="Back to all orders" />
            <br />
            <Banner>
                <p className="p3">
                    Status: Fulfilled {format(details.fullfilledAt, 'M/d/yy')} by FedEx{' '}
                    <a className="bold color-pv-black" href="/">
                        #{details.trackingId}
                    </a>
                    .
                </p>
            </Banner>

            <Table className={accountStyles.accountOrderDetailsTable} basic fixed>
                <Table.Header>
                    <Table.Row>{details.headers.map(headerCell)}</Table.Row>
                </Table.Header>
                <Table.Body>
                    {details.data.map(BodyRow)}

                    <TableSeparator />

                    <Table.Row colSpan={5} className={accountStyles.hr}></Table.Row>
                    {details.surcharges.map(TaxesRow)}

                    <TableSeparator />

                    <Table.Row>
                        <Table.Cell colSpan={4} textAlign="right">
                            Total
                        </Table.Cell>
                        <Table.Cell>${details.total}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <Grid columns={2} padded="vertically">
                <Grid.Row>
                    <Grid.Column width={8}>
                        <h5 className="upper bold">Billing Address</h5>
                        <p className="p2">
                            {details.billingAddress.name}
                            <br />
                            {details.billingAddress.street}
                            <br />
                            {details.billingAddress.city}, {details.billingAddress.state}{' '}
                            {details.billingAddress.zipCode}
                        </p>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <h5 className="upper bold">Shipping Address</h5>
                        <p className="p2">
                            {details.shippingAddress.name}
                            <br />
                            {details.shippingAddress.street}
                            <br />
                            {details.shippingAddress.city}, {details.shippingAddress.state}{' '}
                            {details.shippingAddress.zipCode}
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    </AccountPageWrap>
);

const mapStateToProps = (state) => ({
    details: Selectors.account.orderDetails(state),
});

export default connect(mapStateToProps)(OrderDetailsPage);
