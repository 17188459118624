/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';
import { isEmpty } from 'lodash';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { format, isFuture, isValid, parse } from 'date-fns';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { Button, Form, Divider, Grid, Select } from 'semantic-ui-react';

import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';
import PhoneInputField from 'src/components/account/PhoneInputField';
import {
    getErrorMessage,
    FORM_MESSAGES,
    validateEmail,
    validateInputName,
    MAX_INPUT_LENGTH,
    GENERAL_ERROR_MSG,
} from 'src/utils/form-utils';
import { Banner } from 'src/components/shared';

import * as Styles from 'src/styles/account.module.scss';
import { Icon } from '../shared';
import { UserAttributes } from 'src/state/account/selectors';

const DATE_FORMAT = 'yyyy-MM-dd'; // format in/out of SDK
const DATE_DISPLAY_FORMAT = 'MM/dd/yyyy'; // format presented in UI

const genderOptions = [
    { key: '0', value: '', text: '--' },
    { key: 'female', value: 'female', text: 'Female' },
    { key: 'male', value: 'male', text: 'Male' },
    { key: 'non-binary', value: 'non-binary', text: 'Non-Binary' },
    { key: 'other', value: 'other', text: 'Other' },
];

interface FormValues {
    version: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    birthday?: string;
    gender?: string;
    phone?: string;
}

type field = keyof FormValues;
type Errors = {
    [key in field]?: string;
};

const connector = connect((state: IState) => ({
    loading: Selectors.account.loading(state),
}));

interface AccountSettingsFormProps {
    isSocialNetworkLogged: boolean;
    userAttributes: UserAttributes;
}

type CombinedProps = AccountSettingsFormProps & ConnectedProps<typeof connector>;

const AccountSettingsForm = ({
    dirty,
    values,
    errors,
    status,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    dispatch,
    loading,
    isSocialNetworkLogged,
}: CombinedProps & FormikProps<FormValues>) => {
    const [arrow, setArrow] = useState('down');
    const shopifyUrl = useSelector(PvolveSelectors.config.shopifyUrl);

    useEffect(() => {
        if (!values.version) {
            dispatch(Actions.account.getAllAttrs.trigger());
        }
    }, [dispatch, values]);

    return (
        <Form onSubmit={handleSubmit} className={Styles.accountSettings}>
            {status?.successMessage && (
                <Banner type="SUCCESS" aria-live="assertive">
                    {status.successMessage}
                </Banner>
            )}
            {status?.error && (
                <Banner type="ERROR" aria-live="assertive">
                    {status.error}
                </Banner>
            )}
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            label={{
                                children: 'First Name',
                                htmlFor: 'account-first-name',
                            }}
                            id="account-first-name"
                            name="firstName"
                            type="text"
                            placeholder="First Name"
                            maxLength={MAX_INPUT_LENGTH}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            error={errors.firstName ? getErrorMessage(errors.firstName) : false}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            label={{
                                children: 'Last Name',
                                htmlFor: 'account-last-name',
                            }}
                            id="account-last-name"
                            name="lastName"
                            type="text"
                            placeholder="Last Name"
                            maxLength={MAX_INPUT_LENGTH}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            error={errors.lastName ? getErrorMessage(errors.lastName) : false}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            label={{
                                children: 'Email',
                                htmlFor: 'account-email',
                            }}
                            id="account-email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={true}
                            value={values.email}
                            error={errors.email ? getErrorMessage(errors.email) : false}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Input
                            label={{
                                children: `Birthday (${DATE_DISPLAY_FORMAT.toUpperCase()})`,
                                htmlFor: 'account-birthday',
                            }}
                            id="account-birthday"
                            name="birthday"
                            type="text"
                            placeholder={`Birthday (${DATE_DISPLAY_FORMAT.toUpperCase()})`}
                            onChange={(e) => {
                                const formattedMonth = e.target.value.replace(
                                    /^(\d\d)(\d)$/g,
                                    '$1/$2'
                                );
                                const formattedDay = formattedMonth.replace(
                                    /^(\d\d\/\d\d)(\d+)$/g,
                                    '$1/$2'
                                );
                                const formattedYear = formattedDay.replace(/[^\d\/]/g, '');
                                e.target.value = formattedYear;
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            value={values.birthday}
                            error={
                                touched.birthday && errors.birthday
                                    ? getErrorMessage(errors.birthday)
                                    : false
                            }
                        />
                        {!values.birthday && (
                            <p className={`p3 ${Styles.infoText}`}>
                                <Icon name="pv-info" /> Please enter for exclusive perks.
                            </p>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field
                            id="account-gender"
                            name="gender"
                            label="Gender"
                            placeholder="Gender"
                            control={Select}
                            onChange={(e, value) => handleChange({ target: value })}
                            onBlur={handleBlur}
                            value={values.gender}
                            error={
                                touched.gender && errors.gender
                                    ? getErrorMessage(errors.gender)
                                    : false
                            }
                            options={genderOptions}
                            onOpen={() => setArrow('up')}
                            onClose={() => setArrow('down')}
                            icon={`chevron ${arrow}`}
                        />
                        {!values.gender && (
                            <p className={`p3 ${Styles.infoText}`}>
                                <Icon name="pv-info" /> Please enter to help customize your
                                experience.
                            </p>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <PhoneInputField
                            form={{
                                errors,
                                handleBlur,
                                setFieldValue,
                                touched,
                            }}
                            field={{
                                name: 'phone',
                                value: values.phone,
                            }}
                        />
                        {!values.phone && (
                            <>
                                <p className={`p3 ${Styles.infoText}`}>
                                    <Icon name="pv-info" /> Please enter for updates and exclusive
                                    offers.
                                </p>
                                <p className={`p3`}>
                                    By providing my phone number, I consent to receive text messages
                                    through an automatic telephone dialing system from Pvolve
                                    Promotions, consisting of sales, product launches, on-demand
                                    updates and more. Message frequency varies. Consent is not a
                                    condition to purchase. STOP to cancel, HELP for help. Message
                                    and data rates apply. View{' '}
                                    <a href={`${shopifyUrl}/pages/privacy-policy`}>
                                        privacy policy
                                    </a>{' '}
                                    &amp;{' '}
                                    <a href={`${shopifyUrl}/pages/terms-of-service`}>
                                        terms of service
                                    </a>
                                    .
                                </p>
                            </>
                        )}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            className={Styles.accountSettingsButton}
                            type="submit"
                            disabled={isSubmitting || loading || !dirty || !isEmpty(errors)}
                            secondary
                        >
                            Save Updates
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Divider className={Styles.accountSettingsDivider} />
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            as={LinkWithPrevPath}
                            to={`/account/password`}
                            disabled={isSocialNetworkLogged}
                            primary
                        >
                            Change Password
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const formatBirthday = (birthday: string) => {
    try {
        return birthday.length === 10
            ? format(parse(birthday, DATE_DISPLAY_FORMAT, new Date()), DATE_FORMAT)
            : birthday;
    } catch (e: any) {
        return birthday;
    }
};

const handleSubmit = (
    formValues: FormValues,
    { props, setSubmitting, resetForm, setStatus }: FormikBag<CombinedProps, FormValues>
) => {
    const newVersion = formValues.version + 1;
    const onFailure = () => setStatus({ error: GENERAL_ERROR_MSG });
    const onFulfill = () => setSubmitting(false);
    const onSuccess = () => {
        const phoneValueChanged = props.userAttributes.object?.phone !== formValues.phone;
        if (formValues.email && phoneValueChanged) {
            props.dispatch(
                Actions.segment.identify({
                    userId: formValues.email,
                    traits: {
                        phone: formValues.phone,
                        sms_marketing_opted_in: isEmpty(formValues.phone) ? null : true,
                    },
                })
            );
            props.dispatch(
                Actions.segment.track.marketingOptedIn({
                    phone: isEmpty(formValues.phone) ? null : formValues.phone,
                    is_sms_opt_in: !isEmpty(formValues.phone),
                    source: 'app.pvolve.com',
                    source_detail: 'account',
                })
            );
        }
        props.dispatch(Actions.auth.refresh.trigger());
        props.dispatch(Actions.account.getAllAttrs.trigger());
        resetForm({ values: { ...formValues, version: newVersion } });
        setStatus({ successMessage: 'Account successfully updated' });
    };

    const { birthday } = formValues;

    props.dispatch(
        Actions.account.saveUserAttrs.trigger({
            ...formValues,
            birthday: birthday ? formatBirthday(birthday) : undefined,
            version: newVersion,
            onFailure,
            onFulfill,
            onSuccess,
        })
    );
};

const validate = (values: FormValues) => {
    const errors: Errors = {};

    const firstNameError = validateInputName(values.firstName);
    if (firstNameError) {
        errors.firstName = firstNameError;
    }

    const lastNameError = validateInputName(values.lastName);
    if (lastNameError) {
        errors.lastName = lastNameError;
    }

    const emailError = validateEmail(values);
    if (emailError.email) {
        errors.email = emailError.email;
    }

    if (values.birthday) {
        const formattedBirthday = formatBirthday(values.birthday);
        const date = new Date(formattedBirthday);

        if (formattedBirthday === values.birthday) {
            errors.birthday = 'Must be in mm/dd/yyyy format';
        } else if (isFuture(date) || !isValid(date)) {
            errors.birthday = FORM_MESSAGES.invalidDate;
        }
    }

    if (!isEmpty(values.phone) && !isPossiblePhoneNumber(values.phone)) {
        errors.phone = FORM_MESSAGES.invalidPhone;
    }

    return errors;
};

const mapPropsToValues = ({ userAttributes: { version, object } }: AccountSettingsFormProps) => ({
    version: version,
    firstName: object?.first_name || '',
    lastName: object?.last_name || '',
    email: object?.email || '',
    birthday: object?.birthday
        ? format(parse(object.birthday, DATE_FORMAT, new Date()), DATE_DISPLAY_FORMAT)
        : '',
    gender: object?.gender,
    phone: object?.phone,
});

const FormikAccountSettingsForm = withFormik<CombinedProps, FormValues>({
    handleSubmit,
    validate,
    mapPropsToValues,
    enableReinitialize: true,
})(AccountSettingsForm);

export default connector(FormikAccountSettingsForm);
