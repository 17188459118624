import React from 'react';
import { Link } from 'gatsby';
import { Button } from 'semantic-ui-react';

import * as Styles from 'src/styles/cancel-subscription.module.scss';
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';

import { ZELDA_HOME_LINK } from 'src/constants/url-constants';

interface ConfirmCancellationProps {
    endDate: string | undefined;
    disabled: boolean;
    buttonIds: {
        cancelAnyway: string;
        [x: string]: string;
    };
    onClick: ((...args: any[]) => void) | undefined;
}

const ConfirmCancellation = ({
    endDate,
    disabled,
    buttonIds,
    onClick,
}: ConfirmCancellationProps) => {
    const isTabletPortraitOrBelow = useBreakpoints(Breakpoints.xxl, Directions.down);
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);

    const btnSize = isTabletPortraitOrBelow ? (isMobile ? 'small' : 'medium') : 'large';

    const renderItemsList = () => {
        let items = [
            'On-demand workouts',
            'Live Virtual Studio classes (unless purchased separately)',
        ];
        return items.map((item) => (
            <li className={Styles.cancelSubscriptionListItem} key={item}>
                {item}
            </li>
        ));
    };

    return (
        <>
            <p className={Styles.cancelSubscriptionText}>
                Your membership will remain active until {endDate}. After that, you will no longer
                have access to:
            </p>
            <ul className="margin-top--small">{renderItemsList()}</ul>
            <Button
                id={buttonIds.cancelAnyway}
                className="margin-top--large"
                negative
                size={btnSize}
                onClick={onClick}
                disabled={disabled}
            >
                CANCEL ANYWAY
            </Button>
            <Link
                to={ZELDA_HOME_LINK}
                className={Styles.cancelSubscriptionLink}
                datatype="cancellation-nevermind"
                onClick={onClick}
            >
                Nevermind! I want to keep working out.
            </Link>
        </>
    );
};

export default ConfirmCancellation;
