import React from 'react';
import { Button } from 'semantic-ui-react';
import { Payment } from '@pvolve/sdk/src/CommerceService';
import { paymentExpired } from '@pvolve/sdk/src/app/modules/subscriptions/utils';
import GridLayout from './GridLayout';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';

interface Props {
    paymentMethod?: Payment;
}

const PaymentDetails = ({ paymentMethod }: Props) => {
    let columnContent;

    if (!paymentMethod) {
        columnContent = <p className="p2 color-pv-red">no payment method on file</p>;
    } else {
        const expired = paymentExpired(paymentMethod);
        const className = expired ? 'p2 color-pv-red' : 'p2';
        const { brand, last_four, exp_month, exp_year } = paymentMethod.credit_card;
        columnContent = (
            <>
                <p className="p2 margin--0">
                    {brand} **** **** {last_four}
                </p>
                <p className={className}>
                    exp {exp_month}/{exp_year}
                </p>
            </>
        );
    }

    return (
        <GridLayout
            leftContent={
                <>
                    <h3 className="upper bold">Payment Details</h3>
                    {columnContent}
                </>
            }
            rightContent={
                <>
                    <Button as={LinkWithPrevPath} to="/account/payment/update">
                        Update payment
                    </Button>
                </>
            }
        />
    );
};

export default PaymentDetails;
