import React from 'react';
import classnames from 'classnames';
import { Icon } from 'src/components/shared';

import * as Styles from 'src/styles/subscription-badge.module.scss';

interface Props {
    status: string;
}

const SubscriptionStatusBadge = ({ status }: Props) => {
    const statusLowerCase = status.toLowerCase();
    const badgeClassnames = classnames(Styles.badge, Styles[statusLowerCase]);

    const iconList: { [key: string]: string } = {
        active: 'pv-checkmark-circle',
        expiring: 'pv-alert-circle-outline',
        canceled: 'times circle',
    };

    return (
        <div className={badgeClassnames}>
            <Icon name={iconList[statusLowerCase]} />
            <p className="small bold accent">{statusLowerCase}</p>
        </div>
    );
};

export default SubscriptionStatusBadge;
