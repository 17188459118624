import { isEmpty, pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import { FormikProps, withFormik } from 'formik';
import classNames from 'classnames';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import Actions from 'src/state/root-actions';
import Selectors from 'src/state/root-selectors.js';

import { Banner, InfoMessage, SquareCloseButton, Icon } from 'src/components/shared';
import * as authStyles from 'src/styles/auth.module.scss';

import { getErrorMessage } from 'src/utils/form-utils';
import Layout from 'src/components/layout/Layout';

interface FormValues {
    oldPassword: string;
    newPassword: string;
    error?: string;
}

type field = keyof FormValues;
type Errors = {
    [key in field]?: string;
};

const connector = connect((state: IState) => ({
    banners: Selectors.promos.banners(state),
    user: Selectors.account.userAttributes(state) || {},
}));

// Types that are not from Formik
type CombinedProps = ConnectedProps<typeof connector>;

const pageContext = {
    theme: {
        layout: 'plain',
    },
};

const ChangePassword = ({
    banners,
    dirty,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    user,
    values,
    isValid,
    location,
}: FormikProps<FormValues> & CombinedProps) => {
    const validLengthConstraint = values.newPassword.length >= 8;
    const validUpperConstraint = /[A-Z]/.test(values.newPassword);
    const validNumberConstraint = /[0-9]/.test(values.newPassword);

    const [validLength, setValidLength] = useState(validLengthConstraint);
    const [validNumber, setValidNumber] = useState(validNumberConstraint);
    const [validUpper, setValidUpper] = useState(validUpperConstraint);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);

    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(!showPassword);

    useEffect(() => {
        setValidLength(validLengthConstraint);
        setValidNumber(validNumberConstraint);
        setValidUpper(validUpperConstraint);
    }, [validLengthConstraint, validNumberConstraint, validUpperConstraint]);

    return (
        <Layout pageContext={pageContext} location={location}>
            <div className={authStyles.authWrapper}>
                <SquareCloseButton
                    className={classNames(
                        authStyles.closeButton,
                        isEmpty(banners) ? authStyles.noBanner : authStyles.banner
                    )}
                />
                <div className={authStyles.wrapper}>
                    <Grid className={authStyles.mainColumnWrapper} centered columns={1}>
                        <Grid.Row>
                            <Grid.Column className={authStyles.headerWrapper}>
                                <h5 className="h5 bold upper">Change Your Password</h5>
                                <p>
                                    Create a new password for your account associated with{' '}
                                    {user.object?.email}.
                                </p>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column className={authStyles.formWrapper}>
                                <Form onSubmit={handleSubmit}>
                                    {errors?.error && <Banner type="ERROR">{errors.error}</Banner>}
                                    <Form.Field
                                        className={classNames(
                                            authStyles.passwordField,
                                            authStyles.oldPassword
                                        )}
                                    >
                                        <Form.Input
                                            className={authStyles.passwordIconButton}
                                            icon={
                                                <Icon
                                                    name={`pv-eyeball${
                                                        showOldPassword ? '-off' : ''
                                                    }`}
                                                    onClick={toggleShowOldPassword}
                                                />
                                            }
                                            label={{
                                                children: 'Current Password',
                                                htmlFor: 'old-password',
                                                className: values.oldPassword ? '' : 'hidden',
                                            }}
                                            id="old-password"
                                            name="oldPassword"
                                            type={showOldPassword ? 'text' : 'password'}
                                            placeholder="Current Password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.oldPassword}
                                            error={
                                                errors.oldPassword &&
                                                touched.oldPassword &&
                                                getErrorMessage(errors.oldPassword)
                                            }
                                        />
                                    </Form.Field>

                                    <Form.Field className={authStyles.newPassword}>
                                        <Form.Input
                                            className={authStyles.passwordIconButton}
                                            icon={
                                                <Icon
                                                    name={`pv-eyeball${showPassword ? '-off' : ''}`}
                                                    onClick={toggleShowPassword}
                                                />
                                            }
                                            label={{
                                                children: 'New Password',
                                                htmlFor: 'new-password',
                                                className: values.newPassword ? '' : 'hidden',
                                            }}
                                            id="new-password"
                                            name="newPassword"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="New Password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.newPassword}
                                            maxLength={99}
                                        />
                                        {validLength ? (
                                            <InfoMessage
                                                content="must be at least 8 characters"
                                                type="SUCCESS"
                                            />
                                        ) : (
                                            <InfoMessage content="must be at least 8 characters" />
                                        )}

                                        {validUpper ? (
                                            <InfoMessage
                                                content="contain at least 1 uppercase letter"
                                                type="SUCCESS"
                                            />
                                        ) : (
                                            <InfoMessage content="contain at least 1 uppercase letter" />
                                        )}

                                        {validNumber ? (
                                            <InfoMessage
                                                content="contain at least 1 number"
                                                type="SUCCESS"
                                            />
                                        ) : (
                                            <InfoMessage content="contain at least 1 number" />
                                        )}
                                    </Form.Field>

                                    <Button
                                        type="submit"
                                        disabled={isSubmitting || !isValid || !dirty}
                                        loading={isSubmitting}
                                        primary
                                        className="margin-top--large"
                                    >
                                        SAVE PASSWORD
                                    </Button>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        </Layout>
    );
};

const FormikChangePasswordForm = withFormik<ConnectedProps<typeof connector>, FormValues>({
    handleSubmit: (values, { props, setFieldError, setSubmitting }) => {
        const onFulfill = () => setSubmitting(false);
        const onFailure = (message: string) => setFieldError('error', message);
        const onSuccess = () => {
            navigate('/account', { state: { passwordChanged: true } });
        };

        const _values = pick(values, ['oldPassword', 'newPassword']);

        props.dispatch(
            Actions.auth.changePassword.trigger({
                ..._values,
                onFailure,
                onFulfill,
                onSuccess,
            })
        );
    },
    validate: (values) => {
        const errors: Errors = {};

        const validLengthConstraint = values.newPassword.length >= 8;
        const validUpperConstraint = /[A-Z]/.test(values.newPassword);
        const validNumberConstraint = /[0-9]/.test(values.newPassword);

        if (isEmpty(values.oldPassword)) {
            errors.oldPassword = 'cannot be blank';
        }

        if (
            isEmpty(values.newPassword) ||
            !validLengthConstraint ||
            !validUpperConstraint ||
            !validNumberConstraint
        ) {
            errors.newPassword = 'is not valid';
        }

        return errors;
    },
    mapPropsToValues: () => {
        return {
            oldPassword: '',
            newPassword: '',
        };
    },
})(ChangePassword);

export default connector(FormikChangePasswordForm);
