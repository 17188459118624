import { WindowLocation } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import { connect, ConnectedProps } from 'react-redux';
import { navigate } from 'gatsby';
import AccountPageWrap from 'src/components/account/AccountPageWrap';
import AccountSettingsForm from 'src/components/account/AccountSettingsForm';
import withLocation from 'src/components/withLocation';
import Selectors from 'src/state/root-selectors';
import { Banner } from 'src/components/shared';
import Pvolve from '@pvolve/sdk';
import AppSelectors from '@pvolve/sdk/src/app/modules/subscriptions/selectors';
import { useSelector } from 'react-redux';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import LocalStorageTokenStore from '@pvolve/sdk/src/LocalStorageTokenStore';

const connector = connect((state: IState) => ({
    userAttributes: Selectors.account.userAttributes(state) || {},
    subscription: AppSelectors.currentSubscription(state),
}));
interface LocationState {
    passwordChanged: boolean;
}
interface AccountSettingsProps extends ConnectedProps<typeof connector> {
    location: WindowLocation<LocationState>;
}

const AccountSettings = ({
    location: { pathname, state },
    userAttributes,
    subscription,
}: AccountSettingsProps) => {
    const [isSocialNetworkLogged, setProviderStatus] = useState<boolean>(false);

    useEffect(() => {
        const email = userAttributes.object?.email;
        if (email) {
            findUser(email);
        }
    }, [userAttributes]);

    const findUser = async (email: string) => {
        if (email) {
            const user = await Pvolve.api.account.findUserByEmail(email);
            const isSocialNetworkProvider = user?.provider !== 'Pvolve';
            setProviderStatus(isSocialNetworkProvider);
        }
    };

    return (
        <AccountPageWrap path={pathname} title="Account">
            {state?.passwordChanged && (
                <Banner type="SUCCESS">Password successfully updated.</Banner>
            )}
            <AccountSettingsForm
                isSocialNetworkLogged={isSocialNetworkLogged}
                userAttributes={userAttributes}
            />
        </AccountPageWrap>
    );
};

export default connector(withLocation(AccountSettings));
