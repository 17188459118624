import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Grid } from 'semantic-ui-react';
import { WindowLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Layout from 'src/components/layout/Layout';
import withLocation from 'src/components/withLocation';
import { SquareCloseButton } from 'src/components/shared';
import CardDetails from './start-subscription/CardDetails';

import * as Styles from 'src/styles/cancel-subscription.module.scss';

import { IState } from '@pvolve/sdk/src/redux/selectors';
import Selectors from '@pvolve/sdk/src/app/selectors';
import Actions from '@pvolve/sdk/src/app/actions';
import {
    subscriptionEndDate,
    pricePerPeriodDescription,
} from '@pvolve/sdk/src/app/modules/subscriptions/utils';

const connector = connect((state: IState) => ({
    subscription: Selectors.subscriptions.currentSubscription(state),
    product: Selectors.subscriptions.currentSubscriptionProduct(state),
    paymentMethod: Selectors.subscriptions.paymentMethod(state),
}));

interface StartSubscriptionProps extends ConnectedProps<typeof connector> {
    location: WindowLocation;
}

const StartSubscription = ({
    location,
    subscription,
    product,
    paymentMethod,
    dispatch,
}: StartSubscriptionProps) => {
    const pageContext = {
        theme: {
            layout: 'plain',
        },
    };

    const onSubscribeClick = () => {
        const { sku } = product;
        dispatch(
            Actions.subscriptions.updatePlan.trigger({
                new_sku: sku,
                onSuccess: () => navigate('/account/subscription'),
            })
        );
    };

    const disabled = !subscription || !product;

    return (
        <Layout pageContext={pageContext} location={location}>
            <div className={Styles.cancelSubscriptionPage}>
                <SquareCloseButton />

                <Grid centered>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <h5 className="bold upper">Start Subscription</h5>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p className="p2 bold accent margin--0">next payment</p>
                            <p className="p2">{subscriptionEndDate(subscription)}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p className="p2 bold accent margin--0">plan</p>
                            <p className="p2">{pricePerPeriodDescription(product, true)}</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p className="p2 bold accent margin--0">card on file</p>
                            <p className="p2">
                                <CardDetails paymentMethod={paymentMethod} />
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <p className="p2">
                                After each term, your plan will automatically renew unless you
                                cancel at least 24 hours before the end of the current term. You can
                                manage your subscription via “Subscriptions” found in your account
                                settings. By selecting “SUBSCRIBE,” you agree to the above terms.
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Button onClick={onSubscribeClick}>Subscribe</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        </Layout>
    );
};

export default connector(withLocation(StartSubscription));
