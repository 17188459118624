import React from 'react';
import { Icon, Table } from 'semantic-ui-react';

import * as accountStyles from 'src/styles/account.module.scss';

const HeaderRow = ({ headers, sort, sortProp, activeSort }) => {
    const headerCell = ({ key, title }) => {
        let indicator = null;
        if (activeSort.key === key) {
            const name = activeSort.direction === 'ascending' ? 'angle up' : 'angle down';
            indicator = <Icon name={name} />;
        }

        return (
            <Table.HeaderCell key={key} onClick={sort.bind(this, key)} {...sortProp(title)}>
                <span>{title || key}</span>
                <span className="header-indicator">{indicator}</span>
            </Table.HeaderCell>
        );
    };

    return (
        <Table.Row className={accountStyles.accountOrdersHeaderRow}>
            {headers.map(headerCell)}
        </Table.Row>
    );
};

export default HeaderRow;
