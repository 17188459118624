import React from 'react';
import { Payment } from '@pvolve/sdk/src/CommerceService';
import { paymentExpired } from '@pvolve/sdk/src/app/modules/subscriptions/utils';

interface Props {
    paymentMethod: Payment;
}

const CardDetails = ({ paymentMethod }: Props) => {
    if (!paymentMethod) {
        return null;
    }
    const expired = paymentExpired(paymentMethod);
    const className = expired ? 'p2 color-pv-red' : 'p2';
    const { brand, last_four, exp_month, exp_year } = paymentMethod.credit_card;
    return (
        <>
            <span className="p2 margin--0">
                {brand} **** **** {last_four}
            </span>{' '}
            <span className={className}>
                exp {exp_month}/{exp_year}
            </span>
        </>
    );
};

export default CardDetails;
