import React from 'react';
import { Button } from 'semantic-ui-react';

import * as Styles from 'src/styles/cancel-subscription.module.scss';
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';

const A: React.FC = (props) => <a {...props} />;

interface CTASectionProps {
    onClick: ((...args: any[]) => void) | undefined;
}
const CTASection = ({ onClick }: CTASectionProps) => {
    const isTabletPortraitOrBelow = useBreakpoints(Breakpoints.xxl, Directions.down);
    const isMobile = useBreakpoints(Breakpoints.sm, Directions.down);

    const btnSize = isTabletPortraitOrBelow ? (isMobile ? 'small' : 'medium') : 'large';

    return (
        <>
            <p className={Styles.cancelSubscriptionText}>
                Before you do, let’s see if we can help.
            </p>
            <div className="margin-top--large">
                <p className={Styles.cancelSubscriptionText}>Trouble with billing?</p>
                <Button
                    as={A}
                    size={btnSize}
                    href="https://www.pvolve.com/pages/contact"
                    datatype="cancellation-contactCustomerService"
                    onClick={onClick}
                >
                    CONTACT CUSTOMER SERVICE
                </Button>
            </div>
            <div className="margin-top--large">
                <p className={Styles.cancelSubscriptionText}>Questions about the method?</p>
                <Button
                    as={A}
                    size={btnSize}
                    target="_blank"
                    href="https://calendly.com/pvolve/15min?month=2021-09"
                    datatype="cancellation-scheduleFreeTrainerSession"
                    onClick={onClick}
                >
                    SCHEDULE FREE TRAINER SESSION
                </Button>
            </div>
        </>
    );
};

export default CTASection;
