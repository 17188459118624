import React from 'react';

import { connect, ConnectedProps } from 'react-redux';
import { useLocation, WindowLocation } from '@reach/router';

import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';
import { IState } from '@pvolve/sdk/src/redux/selectors';

const mapStateToProps = (state: IState) => ({
    loggedIn: Selectors.auth.loggedIn(state),
});

const connector = connect(mapStateToProps, {
    loginRequired: (checkout: boolean, prevPath?: string, replace?: boolean) => {
        const payload: { state: any; replace?: boolean } = {
            state: { checkout, prevPath },
        };

        if (replace) {
            payload.replace = replace;
        }

        return Actions.web.loginRequired(payload);
    },
});

interface PrivateRouteProps extends ConnectedProps<typeof connector> {
    component: typeof React.Component;
    checkout: boolean;
    prevPath?: string;
    replace?: boolean;
}

const PrivateRoute = ({
    component: Component,
    loggedIn,
    loginRequired,
    checkout = false,
    prevPath,
    replace,
    ...rest
}: PrivateRouteProps) => {
    const { state } = useLocation() as WindowLocation<PrivateRouteProps>;

    // If we’re not logged in, redirect to SSO
    if (!loggedIn) {
        loginRequired(checkout, prevPath, replace);
        return null;
    }

    return <Component prevPath={state?.prevPath || prevPath} {...rest} />;
};

export default connector(PrivateRoute);
