import React, { useState, useCallback } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';

import * as FormStyles from 'src/styles/upgrade-plan.module.scss';
import { SquareCloseButton } from 'src/components/shared';
import PromoInput from './upgrade-plan/PromoInput';

import Pvolve from '@pvolve/sdk';
import { Discount } from '@pvolve/sdk/src/CommerceService';
import Selectors from '@pvolve/sdk/src/app/selectors';
import ProductGroup from '@pvolve/sdk/src/app/modules/subscriptions/ProductGroup';
import Actions from '@pvolve/sdk/src/app/actions';
import { navigate } from '@reach/router';
import Layout from 'src/components/layout/Layout';
import { notifyBugsnagError } from 'src/utils/bugsnag';

const enhancer = withFormik({
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit: (values, { setSubmitting, setStatus, props: { dispatch } }) => {
        setStatus({ errorMessage: null });
        dispatch(
            Actions.subscriptions.updatePlan.trigger({
                promo_code: values.validatedPromoCode,
                onFulfill: () => setSubmitting(false),
                onSuccess: () => {
                    navigate('/account/subscription', {
                        state: {
                            successMessage: 'Promo successfully applied.',
                        },
                    });
                },
                onFailure: (message) => {
                    setStatus({ errorMessage: message });
                },
            })
        );
    },
    mapPropsToValues: ({ currentProduct }) => ({
        promoCode: '',
        selectedPlan: currentProduct?.sku,
    }),
});

const PromoForm = ({
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setStatus,
    values,
    status,
    saving,
    loading,
    currentProduct,
    lastInvoice,
    location,
}) => {
    const [discount, setDiscount] = useState<Discount>();
    const [isInvalidCode, setInvalidCode] = useState<boolean>(false);

    if (!currentProduct) {
        return <h5 className="bold upper margin-bottom--large">Current Subscription Not Found</h5>;
    }
    const productGroup = new ProductGroup([currentProduct], discount);
    const offer = productGroup?.offers[0];

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const validateCode = useCallback(async () => {
        setStatus({ errorMessage: null });
        try {
            const { promoCode } = values;
            const [data] = await Pvolve.api.commerce.getDiscount([promoCode]);
            setInvalidCode(false);

            if (!data) {
                setStatus({ errorMessage: 'Invalid Promo Code' });
            } else if (data.is_expired) {
                setInvalidCode(true);
                setStatus({ errorMessage: 'Promo code has expired' });
            } else if (!data.is_active) {
                setInvalidCode(true);
                setStatus({ errorMessage: 'Promo code is not active' });
            }

            setDiscount(data);
            setFieldValue('validatedPromoCode', promoCode);
        } catch (error) {
            notifyBugsnagError(error);

            if (typeof error === 'string') {
                setStatus({ errorMessage: error });
            } else {
                setStatus({ errorMessage: 'There was a problem adding this promo code.' });
            }
            setDiscount(undefined);
            setFieldValue('validatedPromoCode', null);
        }
    }, [values, setFieldValue, setStatus]);

    const removeCode = () => {
        setFieldValue('promoCode', '');
        setFieldValue('validatedPromoCode', null);
        setStatus({ errorMessage: null });
        setDiscount(undefined);
    };

    const pageContext = {
        theme: {
            layout: 'plain',
        },
    };

    const disabled = saving || loading;
    const price = (lastInvoice?.amount / 100).toFixed(2) || offer.product.cost.currency_amount;
    const interval = offer.product.subscription_details.interval;
    const interval_type = offer.product.subscription_details.interval_type;
    const displayCurrentPrice = () => {
        if (interval > 1) {
            return `${price} / ${interval} ${interval_type}s`;
        }

        return `${price} / ${interval} ${interval_type}`;
    };

    let discountedBillingPrice =
        offer.discountedBillingPrice < price ? offer.discountedBillingPrice : null;

    return (
        <Layout pageContext={pageContext} location={location}>
            <div className={FormStyles.upgradePlanWrapper}>
                <SquareCloseButton />
                <div className={FormStyles.wrapper}>
                    <h5 className="bold upper margin-bottom--large">Apply Promo</h5>
                    <Form>
                        <div>
                            <p className={`p2 accent bold ${FormStyles.currentPlanHt}`}>
                                current plan
                            </p>
                            {discountedBillingPrice && (
                                <div>
                                    <p className={FormStyles.promoPrice}>
                                        ${discountedBillingPrice}
                                    </p>
                                </div>
                            )}
                            <p className="p2">${displayCurrentPrice()}</p>
                        </div>
                        <hr className={FormStyles.line} />

                        <PromoInput
                            values={values}
                            handleChange={handleChange}
                            onApplyClick={validateCode}
                            onRemoveClick={removeCode}
                            appliedPromo={discount}
                            promoDescription={productGroup.discountDescription}
                            errorState={status}
                        />

                        <Button
                            onClick={handleSubmit}
                            disabled={disabled || !discount || isInvalidCode}
                            size="large"
                            className="margin-top--large"
                        >
                            Save Plan
                        </Button>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    lastInvoice: Selectors.subscriptions.lastInvoice(state),
    currentProduct: Selectors.subscriptions.currentSubscriptionProduct(state),
    loading: Selectors.subscriptions.loading(state),
    saving: Selectors.subscriptions.updatingPlan(state),
});

export default connect(mapStateToProps)(enhancer(PromoForm));
