import React, { useState } from 'react';
import { Pagination, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';
import classnames from 'classnames';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import Selectors from 'src/state/root-selectors.js';

import AccountPageWrap from 'src/components/account/AccountPageWrap';
import HeaderRow from 'src/components/account/HeaderRow';
import BodyRow from 'src/components/account/BodyRow';
import * as accountStyles from 'src/styles/account.module.scss';

import { Banner } from 'src/components/shared';

const ROWS_PER_PAGE = 50;
const minHeight = ROWS_PER_PAGE * 34 + 10;

const connector = connect((state) => ({
    orders: Selectors.account.orders(state),
    shopifyUrl: PvolveSelectors.config.shopifyUrl(state),
}));

const AccountOrdersPage = ({ path, orders, shopifyUrl }) => {
    const { data, headers } = orders;
    const numberOfPages = Math.ceil(data.length / ROWS_PER_PAGE);
    const showPagination = numberOfPages > 1;
    const [currentPage, setActivePage] = useState(1);
    const [sortData, setSortData] = useState({
        key: 'date',
        direction: 'descending',
        data: sortBy(data, ['date']),
    });

    const onPageChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    const paginate = (data) => {
        const start = (currentPage - 1) * ROWS_PER_PAGE;
        return data.slice(start, start + ROWS_PER_PAGE);
    };

    const sortProp = (key) => (sortData.key === key ? { sorted: sortData.direction } : {});

    const sort = (key) => {
        const { direction } = sortData;
        const sorted = sortBy(data, [key]);
        const options =
            direction === 'ascending'
                ? {
                    direction: 'descending',
                    data: sorted.reverse(),
                }
                : {
                    direction: 'ascending',
                    data: sorted,
                };
        setSortData({ key, ...options });
    };

    const headerRow = () => (
        <HeaderRow sort={sort} sortProp={sortProp} activeSort={sortData} headers={headers} />
    );

    const tableClassnames = classnames(accountStyles.orderTableContainer, accountStyles.orders);

    return (
        <AccountPageWrap path={path} title="Orders" fullWidth>
            <div className={tableClassnames} style={showPagination ? { minHeight } : {}}>
                <Table
                    className={accountStyles.accountOrdersTable}
                    headerRow={headerRow}
                    renderBodyRow={BodyRow}
                    tableData={paginate(sortData.data)}
                    fixed
                    basic
                />
            </div>
            {showPagination && (
                <Pagination
                    defaultActivePage={currentPage}
                    secondary
                    size="tiny"
                    compact
                    totalPages={numberOfPages}
                    onPageChange={onPageChange}
                />
            )}

            <Banner>
                <p className="p3">
                    Issues or questions on an order?
                    <a className="bold" href={`${shopifyUrl}/pages/contact`}>
                        {' '}
                        Contact us
                    </a>{' '}
                    or call{' '}
                    <a href="tel:888-978-3926">
                        <strong>888.978.3926</strong>
                    </a>
                    .
                </p>
            </Banner>
        </AccountPageWrap>
    );
};

export default connector(AccountOrdersPage);
