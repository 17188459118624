import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import SubscriptionStatusBadge from './SubscriptionStatusBadge';
import Selectors from 'src/state/root-selectors';

import { Subscription, Product } from '@pvolve/sdk/src/SubscriptionService';
import {
    subscriptionEndDate,
    subscriptionCancelledDate,
} from '@pvolve/sdk/src/app/modules/subscriptions/utils';
import Actions from '@pvolve/sdk/src/app/actions';
import { useBreakpoints, Directions, Breakpoints } from 'src/utils/breakpoint-utils';

import * as Styles from 'src/styles/subscription-options.module.scss';
import GridLayout from './GridLayout';

import LinkWithPrevPath from 'src/components/LinkWithPrevPath';
import { getStartedUrl } from 'src/components/layout/header/header-constants';

/*

  Subscription statuses

  - ACTIVE
  - EXPIRING
  - CANCELED

*/

interface Props {
    status?: string;
    subscription: Subscription;
    subscriptionStatus: string;
    product: Product;
}

const NextPayment = ({ status = 'ACTIVE', subscription, subscriptionStatus, product }: Props) => {
    // Used if / else if statments here for readability
    // !: Define what content goes into left column of grid
    let leftColumnContent, rightColumnContent;

    const { provider } = subscription;
    const isItunesAccount = provider === 'apple';
    const expDate = subscriptionEndDate(subscription);
    const cancelDate = subscriptionCancelledDate(subscription);
    const shopifyUrl = useSelector(Selectors.config.shopifyUrl);

    const dispatch = useDispatch();
    const reactivate = () => {
        dispatch(
            Actions.subscriptions.reactivate.trigger({
                subscription_id: subscription.subscription_id,
            })
        );
    };

    if (isItunesAccount) {
        // Don't show expiration/payment dates for iTunes account because
        // they may not be accurate
    } else if (subscriptionStatus === 'trial_ending_no_payment') {
        leftColumnContent = <p className="p2">free trial expires {expDate}</p>;
    } else if (['trial_ending_valid_payment'].includes(subscriptionStatus)) {
        leftColumnContent = <p className="p2">access expires {expDate}</p>;
    } else if (subscriptionStatus === 'canceled') {
        leftColumnContent = <p className="p2">canceled {cancelDate}</p>;
    } else if (subscriptionStatus === 'expiring') {
        leftColumnContent = (
            <>
                <p className="p2 margin--0">canceled {cancelDate}</p>
                <p className="p2">access expires {expDate}</p>
            </>
        );
    } else {
        leftColumnContent = <p className="p2">next payment on {expDate}</p>;
    }

    // !: Define what content goes into right column of grid

    if (isItunesAccount) {
        rightColumnContent = (
            <h6 className="upper bold margin--0 color-pv-gray-50">Manage in iTunes</h6>
        );
    } else if (subscriptionStatus === 'trial_ending_valid_payment') {
        rightColumnContent = (
            <Button as={LinkWithPrevPath} to="/account/subscription/start">
                Start Membership
            </Button>
        );
    } else if (subscriptionStatus === 'expiring') {
        rightColumnContent = <Button onClick={reactivate}>Reactivate</Button>;
    } else if (subscriptionStatus === 'canceled') {
        // TODO - link to purchase
        rightColumnContent = (
            <Button as={LinkWithPrevPath} to={getStartedUrl(shopifyUrl)}>
                Reactivate
            </Button>
        );
    } else if (subscriptionStatus === 'trial_ending_no_payment') {
        // No button
    } else {
        rightColumnContent = (
            <Button as={LinkWithPrevPath} to={`/account/subscription/cancel`}>
                Cancel Membership
            </Button>
        );
    }

    return (
        <GridLayout
            leftContent={
                <>
                    <div className={Styles.subRow}>
                        <SubscriptionStatusBadge status={status} />
                        <div className="margin-left--small">{leftColumnContent}</div>
                    </div>
                </>
            }
            rightContent={rightColumnContent}
        />
    );
};

export default NextPayment;
