// extracted by mini-css-extract-plugin
export const mini = "upgrade-plan-module--mini--3jzQG";
export const tiny = "upgrade-plan-module--tiny--3qJtd";
export const small = "upgrade-plan-module--small--2bIUJ";
export const medium = "upgrade-plan-module--medium--2nAjZ";
export const p3 = "upgrade-plan-module--p3--1lxtg";
export const large = "upgrade-plan-module--large--3wGXc";
export const p2 = "upgrade-plan-module--p2--2_BiK";
export const l1 = "upgrade-plan-module--l1--2I8KA";
export const label = "upgrade-plan-module--label--3jXv5";
export const big = "upgrade-plan-module--big--SG7j7";
export const p1 = "upgrade-plan-module--p1--2bpJO";
export const huge = "upgrade-plan-module--huge--3Ad5h";
export const p0 = "upgrade-plan-module--p0--14wD0";
export const huge36 = "upgrade-plan-module--huge36--2jkTL";
export const huge52 = "upgrade-plan-module--huge52--29ifI";
export const massive = "upgrade-plan-module--massive--gVDJT";
export const h1 = "upgrade-plan-module--h1--2upga";
export const wrapper = "upgrade-plan-module--wrapper--1nx0d";
export const h2 = "upgrade-plan-module--h2--3iU62";
export const h3 = "upgrade-plan-module--h3--3DXSa";
export const h4 = "upgrade-plan-module--h4--7zf0g";
export const h5 = "upgrade-plan-module--h5--2cNZg";
export const h6 = "upgrade-plan-module--h6--24jYF";
export const accent = "upgrade-plan-module--accent--_V0zV";
export const accenth5 = "upgrade-plan-module--accenth5--GrNJi";
export const accenth6 = "upgrade-plan-module--accenth6--2hLzh";
export const upper = "upgrade-plan-module--upper--3Q_JV";
export const lower = "upgrade-plan-module--lower--olE-c";
export const underline = "upgrade-plan-module--underline--kVRuO";
export const bold = "upgrade-plan-module--bold--1Pw9k";
export const semibold = "upgrade-plan-module--semibold--3SSe4";
export const normal = "upgrade-plan-module--normal--3MBou";
export const stacked = "upgrade-plan-module--stacked--1nf9g";
export const link = "upgrade-plan-module--link--Zxu5M";
export const textLinks = "upgrade-plan-module--text-links--1kn0b";
export const upgradePlanWrapper = "upgrade-plan-module--upgrade-plan-wrapper--15qL-";
export const planDetails = "upgrade-plan-module--plan-details--3XJ0J";
export const discountTag = "upgrade-plan-module--discount-tag--1lgKP";
export const currentTag = "upgrade-plan-module--current-tag--1sNjA";
export const cardHeaderContainer = "upgrade-plan-module--card-header-container--3XI_k";
export const line = "upgrade-plan-module--line--_vyuG";
export const promoLabel = "upgrade-plan-module--promo-label--3K9bZ";
export const promoButton = "upgrade-plan-module--promo-button--3qJlj";
export const strikethrough = "upgrade-plan-module--strikethrough--BBOHI";
export const divider = "upgrade-plan-module--divider--1XL27";
export const currentPlanHt = "upgrade-plan-module--current-plan-ht--3nZhK";
export const modalDiv = "upgrade-plan-module--modal-div--2ubzr";
export const modalInnerDiv = "upgrade-plan-module--modal-inner-div--BwS85";
export const promoPrice = "upgrade-plan-module--promo-price--1FkOd";
export const upgrade12Month = "upgrade-plan-module--upgrade12-month--3LmcA";
export const upgrade12Link = "upgrade-plan-module--upgrade12-link--3783g";