import { find, isEmpty } from 'lodash';
import React from 'react';
import { Button } from 'semantic-ui-react';
import GridLayout from './GridLayout';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';
import Selectors from '@pvolve/sdk/src/app/selectors';
import { useSelector } from 'react-redux';
import { STATES, COUNTRIES } from 'src/components/shared/calendar/constants';

const UpdateAddress = () => {
    const billingAddress = useSelector(Selectors.subscriptions.billingAddress);
    const noAddress = isEmpty(billingAddress);
    let columnContent;

    if (noAddress) {
        columnContent = <p className="p2 color-pv-red">no address on file</p>;
    } else {
        const { address1, address2, city, zip, province_code, country_code } = billingAddress;

        const state = find(STATES, { value: province_code });
        const country = find(COUNTRIES, { value: country_code });

        columnContent = (
            <>
                <p className="p2 margin--0">
                    {address1} {address2}
                </p>
                <p className="p2 margin--0">
                    {city}, {state?.text} {zip}
                </p>
                <p className="p2 margin--0">{country?.text}</p>
            </>
        );
    }

    return (
        <GridLayout
            leftContent={
                <>
                    <h3 className="upper bold">Billing Address</h3>
                    {columnContent}
                </>
            }
            rightContent={
                <>
                    <Button as={LinkWithPrevPath} to="/account/subscription/address">
                        {noAddress ? 'Add' : 'Update'} billing address
                    </Button>
                </>
            }
        />
    );
};

export default UpdateAddress;
