import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { Menu } from 'semantic-ui-react';
import Selectors from '@pvolve/sdk/src/app/selectors';
import RootSelectors from 'src/state/root-selectors';

import * as Styles from 'src/styles/account.module.scss';
import { ENTITLEMENT_SOURCE } from '@pvolve/sdk/src/app/modules/auth/constants';

interface AccountMenuProps {
    active: string;
}

const AccountMenu = ({ active }: AccountMenuProps) => {
    const entitled = useSelector(Selectors.auth.entitled);
    const paymentMethod = useSelector(Selectors.subscriptions.paymentMethod);
    const userAttributes = useSelector(RootSelectors.account.userAttributes);
    const hasSubscriptions = useSelector(Selectors.subscriptions.hasSubscriptionHistory);

    const showSubscriptions = hasSubscriptions || entitled;
    const partner = userAttributes?.object?.partner?.provider;

    const isOptum = partner === ENTITLEMENT_SOURCE.optum;

    return (
        <Menu className={Styles.accountMenu} vertical fluid>
            <Menu.Item
                className={Styles.accountMenuItem}
                as={Link}
                to="/account"
                active={active === 'account'}
            >
                <span className={Styles.activeIndicator}></span>
                <p className={Styles.itemContent}>Account</p>
            </Menu.Item>
            {showSubscriptions && (
                <Menu.Item
                    className={Styles.accountMenuItem}
                    as={Link}
                    to="/account/settings"
                    active={active === 'settings'}
                >
                    <span className={Styles.activeIndicator}></span>
                    <p className={Styles.itemContent}>Settings</p>
                </Menu.Item>
            )}
            {!isOptum && showSubscriptions && (
                <Menu.Item
                    className={Styles.accountMenuItem}
                    as={Link}
                    to="/account/subscription"
                    active={active === 'subscription'}
                >
                    <span className={Styles.activeIndicator}></span>
                    <p className={Styles.itemContent}>
                        Membership
                        {!paymentMethod && <span className={Styles.dot}></span>}
                    </p>
                </Menu.Item>
            )}
            <Menu.Item
                className={Styles.accountMenuItem}
                as={Link}
                to="/account/communications"
                active={active === 'communications'}
            >
                <span className={Styles.activeIndicator}></span>
                <p className={Styles.itemContent}>Communications</p>
            </Menu.Item>
            <Menu.Item className={Styles.accountMenuItem} as={Link} to="/logout">
                <p className={Styles.itemContent}>Logout</p>
            </Menu.Item>
        </Menu>
    );
};

AccountMenu.propTypes = {
    active: PropTypes.oneOf(['account', 'subscription', 'membership', 'orders', 'communications']),
};

export default AccountMenu;
