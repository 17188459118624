import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import Cookies from 'js-cookie';
import moment from 'moment';
import { WindowLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';

import withLocation from 'src/components/withLocation';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';
import { Banner } from 'src/components/shared';
import AccountPageWrap from 'src/components/account/AccountPageWrap';
import { NextPayment, PlanSettings, PaymentDetails, UpdateAddress } from './subscription-page';
import { IState } from '@pvolve/sdk/src/redux/selectors';
import Selectors from '@pvolve/sdk/src/app/modules/subscriptions/selectors';
import Pvolve from '@pvolve/sdk';
import PvolveSelectors from '@pvolve/sdk/src/app/selectors';
import AuthSelectors, { Token } from '@pvolve/sdk/src/app/modules/auth/selectors';
import { Subscription, Product } from '@pvolve/sdk/src/SubscriptionService';
import Actions from 'src/state/root-actions';
import LocalStorageTokenStore from '@pvolve/sdk/src/LocalStorageTokenStore';

import * as subStyles from 'src/styles/subscription-options.module.scss';

// import Actions from 'src/state/root-actions';

const connector = connect(
    (state: IState) => ({
        subscription: Selectors.currentSubscription(state),
        product: Selectors.currentSubscriptionProduct(state),
        nextProduct: Selectors.nextSubscriptionProduct(state),
        nextDiscount: Selectors.nextSubscriptionDiscount(state),
        payment: Selectors.paymentMethod(state),
        badge: Selectors.subscriptionBadgeStatus(state),
        paymentStatus: Selectors.paymentStatus(state),
        subscriptionStatus: Selectors.subscriptionStatus(state),
        entitled: AuthSelectors.entitled(state),
        user: AuthSelectors.user(state),
        migrationDate: Selectors.legacyUserMigrationDate(state),
        checkedSubscriptions: Selectors.checkedSubscriptions(state),
        currentUserId: AuthSelectors.userId(state),
    }),
    {
        loadSubscriptions: () => Actions.subscriptions.load.trigger(),
        logOut: () => Actions.web.handleLoggedOut.trigger(),
    }
);

interface AccountSubscriptionProps extends ConnectedProps<typeof connector> {
    path: string;
    location: WindowLocation;
    userId?: string;
}

interface WarningProps {
    subscriptionStatus: string;
    subscription: Subscription;
    product: Product;
    user: Token;
}

const Warning = ({
    subscriptionStatus,
    subscription,
    product,
    user,
    migrationDate,
}: WarningProps) => {
    if (!subscription && user?.entitlement_source === 'platform_purple') {
        return (
            <Banner type="ERROR">
                We're currently making system improvements. You will not be able to update your
                account via the web or app until {migrationDate}. To make updates to your account,
                please{' '}
                <Link to="https://www.pvolve.com/pages/contact">
                    <strong>contact us</strong>
                </Link>
                .
            </Banner>
        );
    }

    if (subscriptionStatus === 'active_no_payment') {
        return (
            <Banner type="ERROR">
                It seems we don’t have a payment method on file.
                <LinkWithPrevPath to="/account/payment/update" className={subStyles.message}>
                    <strong> Please update payment</strong>
                </LinkWithPrevPath>
                .
            </Banner>
        );
    } else if (subscriptionStatus === 'active_payment_expired') {
        return (
            <Banner type="ERROR">
                Your credit card is expired and you may soon lose access.
                <LinkWithPrevPath to="/account/payment/update" className={subStyles.message}>
                    <strong> Please update payment</strong>
                </LinkWithPrevPath>
                .
            </Banner>
        );
    } else if (subscriptionStatus === 'trial_ending_no_payment') {
        const {
            subscription_details: { trial_days },
        } = product;
        const { expiration_date } = subscription;
        const daysRemaining = moment(expiration_date).diff(moment(), 'days');
        return (
            <Banner type="ERROR">
                Your {trial_days} day free trial expires in {daysRemaining} days.
                <LinkWithPrevPath to="/account/payment/update" className={subStyles.message}>
                    <strong> Please input a payment method</strong>
                </LinkWithPrevPath>
                .
            </Banner>
        );
    } else if (subscriptionStatus === 'trial_ending_valid_payment') {
        const {
            subscription_details: { trial_days },
        } = product;
        const { expiration_date } = subscription;
        const daysRemaining = moment(expiration_date).diff(moment(), 'days');
        return (
            <Banner type="ERROR">
                Your {trial_days} day access expires in {daysRemaining} days. Please confirm if you
                would like to
                <LinkWithPrevPath to="/account/subscription/start" className={subStyles.message}>
                    <strong> start your subscription </strong>
                </LinkWithPrevPath>
                and bill to the card on file.
            </Banner>
        );
    } else {
        return null;
    }
};

const AccountSubscription = ({
    path,
    subscription,
    product,
    nextProduct,
    nextDiscount,
    subscriptionStatus,
    payment,
    badge,
    entitled,
    user,
    migrationDate,
    location,
    loadSubscriptions,
    checkedSubscriptions,
    currentUserId,
    logOut,
    userId,
}: AccountSubscriptionProps) => {
    const dispatch = useDispatch();

    // Check if user has subscription & if subscription is through Stripe
    // If not Stripe, redirect to Shopify account page
    const shopifyUrl =
        useSelector(PvolveSelectors.config.shopifyUrl) + '/account' ||
        'https://www.pvolve.com/account';
    useEffect(() => {
        const localStorageToken = new LocalStorageTokenStore();
        const getTokens = localStorageToken.getToken();
        const userIdToken = getTokens.id;

        if (subscription && subscription.provider !== 'stripe') {
            Pvolve.api.account.redirectMultipassUser(shopifyUrl, userIdToken).then((res) => {
                // navigate(res.url);
                window.location.href = res.url;
            });
        }
    }, []);

    useEffect(() => {
        // Segment Track Event
        dispatch(Actions.segment.track.appSubscriptionPageViewed());
    }, [dispatch]);

    console.log({ nextDiscount });
    useEffect(() => {
        if (window?.history) {
            window.history.replaceState({}, '', window.location.href);
        }
    }, [location.state]);

    useEffect(() => {
        /* If a user opens the subscription link on mobile, it will have the userId on the url.
        Compare it with the userId that we have in the store and if it doesn't match, logout and
        take the user to the login page. */
        if (userId && userId !== currentUserId) {
            Cookies.set('pv-red', '/continue');
            logOut();
        }
    }, [currentUserId, logOut, userId]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!checkedSubscriptions || !subscription) {
            // Load subscriptions only if we haven't before; plan updates and
            // cancelling involve async webhooks, and if we reload subscriptions
            // immediately after an update we can get stale data
            loadSubscriptions();
        }
    }, []);

    useEffect(() => {
        if (!subscription && !entitled) {
            navigate('/account');
        }
    }, [subscription, entitled]);

    if (!subscription && !entitled) {
        return null;
    }

    return (
        <AccountPageWrap fullWidth path={path} title="Membership">
            {location?.state?.successMessage && (
                <Banner type="SUCCESS">{location.state.successMessage}</Banner>
            )}

            <Warning
                subscriptionStatus={subscriptionStatus}
                subscription={subscription}
                product={product}
                user={user}
                migrationDate={migrationDate}
            />

            <Grid className={subStyles.wrapper}>
                {subscription && (
                    <>
                        <NextPayment
                            subscription={subscription}
                            status={badge}
                            subscriptionStatus={subscriptionStatus}
                        />

                        <PlanSettings
                            isItunesAccount={subscription?.provider === 'apple'}
                            subscription={subscription}
                            product={product}
                            nextProduct={nextProduct}
                            nextDiscount={nextDiscount}
                        />
                    </>
                )}

                {subscription && subscription.provider !== 'apple' && (
                    <>
                        <PaymentDetails paymentMethod={payment} />
                        <UpdateAddress />
                    </>
                )}
            </Grid>
        </AccountPageWrap>
    );
};

export default connector(withLocation(AccountSubscription));
