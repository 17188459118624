import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Selectors from 'src/state/root-selectors';
import Actions from 'src/state/root-actions';

import AccountPageWrap from 'src/components/account/AccountPageWrap';
import SwitchToggle from 'src/components/ui/SwitchToggle';
import * as Styles from 'src/styles/account.module.scss';

interface AccountAppSettingsPageProps {
    path: string;
}

export const AccountAppSettingsPage = ({ path }: AccountAppSettingsPageProps) => {
    const dispatch = useDispatch();
    const toggle = useRef<any>({
        closed_captions: (value: boolean) => onToggle('closed_captions', value),
        feed_fm_disabled: (value: boolean) => onToggle('feed_fm_disabled', !value),
    });

    const showFeedFmToggle =
        useSelector(Selectors.config.featureFlags)?.feedFmToggleEnabled || false;
    const userAttributes = useSelector(Selectors.account.userAttributes);

    const closedCaptionsEnabled = userAttributes?.object?.closed_captions || false;
    const feedFmEnabled = !userAttributes?.object?.feed_fm_disabled || false;

    const onToggle = useCallback(
        (key: string, value: boolean) => {
            dispatch(Actions.account.saveUserAttrs.trigger({ [key]: value }));
        },
        [dispatch]
    );

    return (
        <AccountPageWrap path={path} title="Settings" fullWidth>
            <div className={Styles.rowSettings}>
                <SwitchToggle
                    active={closedCaptionsEnabled}
                    label="closed captions"
                    onChange={toggle.current.closed_captions}
                />
            </div>
            {showFeedFmToggle && (
                <div className="display-row">
                    <SwitchToggle
                        active={feedFmEnabled}
                        onChange={toggle.current.feed_fm_disabled}
                        label="Feed.fm music"
                    />
                </div>
            )}
        </AccountPageWrap>
    );
};

export default AccountAppSettingsPage;
