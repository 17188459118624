import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Form, Button, Grid } from 'semantic-ui-react';
import Layout from 'src/components/layout/Layout';
import { navigate } from '@reach/router';

import { getErrorMessage } from 'src/utils/form-utils';
import { STATES, COUNTRIES } from '../shared/calendar/constants';
import * as Styles from 'src/styles/update-address.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import Actions from '@pvolve/sdk/src/app/modules/subscriptions/actions';
import { SubscriptionBillingAddress } from '@pvolve/sdk/src/SubscriptionService';
import Selectors from '@pvolve/sdk/src/app/selectors';
import * as yup from 'yup';

const Schema = yup.object().shape({
    address1: yup.string().required(),
    address2: yup.string(),
    city: yup.string().required(),
    province_code: yup.string().required(),
    zip: yup.string().required(),
    country_code: yup.string(),
});

const pageContext = {
    theme: {
        layout: 'plain',
    },
};

const addBlankField = (data: any[]) => [{ key: '', value: '', text: '' }, ...data];

const UpgradePlanForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const billingAddress = useSelector(Selectors.subscriptions.billingAddress);

    const initialValues = billingAddress || {
        address1: '',
        address2: '',
        city: '',
        zip: '',
        province_code: '',
        country_code: '',
    };

    const onSubmit = async (billing_address: SubscriptionBillingAddress) => {
        setLoading(true);
        dispatch(Actions.subscriptions.updatePlan.trigger({ billing_address }));
        setTimeout(goBack, 2000);
    };

    const goBack = () => {
        setLoading(false);
        navigate(`/account/subscription`);
    };

    return (
        <Layout pageContext={pageContext}>
            <Formik initialValues={initialValues} validationSchema={Schema} onSubmit={onSubmit}>
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    isValid,
                    errors,
                    touched,
                    setFieldValue,
                }) => {
                    const onSelect = (
                        e: React.ChangeEvent<any>,
                        { name, value }: { name: string; value: string }
                    ) => {
                        setFieldValue(name, value, false);
                    };

                    const isTouched = !isEmpty(touched);

                    return (
                        <form
                            id="update-address"
                            onSubmit={handleSubmit}
                            className={Styles.wrapper}
                        >
                            <Grid stackable>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            label={{
                                                children: 'Address',
                                                htmlFor: 'subscription-address-1',
                                            }}
                                            id="subscription-address-1"
                                            name="address1"
                                            type="text"
                                            placeholder="Street Address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address1}
                                            error={
                                                errors.address1
                                                    ? getErrorMessage(errors.address1)
                                                    : false
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            label={{
                                                children: 'Address 2',
                                                htmlFor: 'subcriptions-address-2',
                                            }}
                                            id="subcriptions-address-2"
                                            name="address2"
                                            type="text"
                                            placeholder="Address: suite, apt #"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.address2}
                                            error={
                                                errors.address2
                                                    ? getErrorMessage(errors.address2)
                                                    : false
                                            }
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column computer={8}>
                                        <Form.Input
                                            label={{
                                                children: 'City',
                                                htmlFor: 'subscription-city',
                                            }}
                                            id="subscription-city"
                                            name="city"
                                            placeholder="City"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.city}
                                            error={
                                                errors.city ? getErrorMessage(errors.city) : false
                                            }
                                        />
                                    </Grid.Column>

                                    <Grid.Column computer={5}>
                                        <Form.Dropdown
                                            label={{
                                                children: 'State',
                                                htmlFor: 'subscription-province_code',
                                            }}
                                            id="subscription-province_code"
                                            placeholder="State"
                                            selection
                                            name="province_code"
                                            autoComplete="state"
                                            onChange={onSelect}
                                            value={values.province_code}
                                            options={addBlankField(STATES)}
                                        />
                                    </Grid.Column>

                                    <Grid.Column computer={3}>
                                        <Form.Input
                                            label={{
                                                children: 'Zip',
                                                htmlFor: 'subscription-zip',
                                            }}
                                            id="subscription-zip"
                                            name="zip"
                                            placeholder="Zip"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.zip}
                                            error={errors.zip ? getErrorMessage(errors.zip) : false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Dropdown
                                            label={{
                                                children: 'Country',
                                                htmlFor: 'subscription-country_code',
                                            }}
                                            id="subscription-country_code"
                                            placeholder="Country"
                                            selection
                                            name="country_code"
                                            autoComplete="country"
                                            onChange={onSelect}
                                            value={values.country_code}
                                            options={addBlankField(COUNTRIES)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column computer={5} tablet={6} mobile={16} stretched>
                                        <Button
                                            type="submit"
                                            disabled={isTouched && (!isValid || loading)}
                                            loading={loading}
                                            secondary
                                        >
                                            Update
                                        </Button>
                                    </Grid.Column>
                                    <Grid.Column computer={5} tablet={6} mobile={16} stretched>
                                        <Button type="button" onClick={goBack} secondary basic>
                                            Cancel
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </form>
                    );
                }}
            </Formik>
        </Layout>
    );
};

export default UpgradePlanForm;
