import React, { useState } from 'react';
import classnames from 'classnames';

import { Grid } from 'semantic-ui-react';

import AccountMenu from 'src/components/account/AccountMenu';
import * as Styles from 'src/styles/account.module.scss';
import Layout from 'src/components/layout/Layout';
import withLocation from 'src/components/withLocation';
import { WindowLocation } from '@reach/router';

import { useBreakpoints, Breakpoints, Directions } from 'src/utils/breakpoint-utils';
import { Icon } from '../shared';

interface AccountPageWrapProps {
    title: string;
    path: string;
    fullWidth?: boolean;
    location: WindowLocation;
}

const AccountPageWrap: React.FC<AccountPageWrapProps> = ({
    title,
    path,
    fullWidth,
    children,
    location,
}) => {
    let activeMenuItem;

    const isTabletPortraitOrBelow = useBreakpoints(Breakpoints.lg, Directions.down);

    const defaultMenuState = isTabletPortraitOrBelow ? false : true;
    const [showMobileMenu, setShowMobileMenu] = useState(defaultMenuState);

    const toggleMobileMenu = () => setShowMobileMenu((prevState) => !prevState);

    if (path.match(/account\/?$/)) {
        activeMenuItem = 'account';
    } else if (path.match(/settings\/?$/)) {
        activeMenuItem = 'settings';
    } else if (path.match(/subscription\/?$/)) {
        activeMenuItem = 'subscription';
    } else if (path.match(/communications\/?$/)) {
        activeMenuItem = 'communications';
    }

    const pageContext = {
        theme: {
            headerBgColor: 'white',
        },
    };

    const columnClassnames = classnames({ 'padding-left--0': isTabletPortraitOrBelow });
    const showAccountMenu = isTabletPortraitOrBelow ? showMobileMenu : true;

    return (
        <Layout pageContext={pageContext} location={location}>
            <div className={Styles.contentWrapper}>
                <div className={Styles.backgroundContainer}></div>
                <Grid className={Styles.accountContainer}>
                    {isTabletPortraitOrBelow && (
                        <Grid.Row className={Styles.openMenu} onClick={toggleMobileMenu}>
                            <h2 className="bold upper margin--0">{activeMenuItem}</h2>
                            <Icon
                                name={showMobileMenu ? 'pv-chevron-up' : 'pv-chevron-down'}
                                size={18}
                            />
                        </Grid.Row>
                    )}

                    {showAccountMenu && (
                        <Grid.Column
                            className={Styles.mobileMenu}
                            width={isTabletPortraitOrBelow ? 16 : 4}
                        >
                            <AccountMenu active={activeMenuItem} />
                        </Grid.Column>
                    )}

                    <Grid.Column
                        className={columnClassnames}
                        width={isTabletPortraitOrBelow ? 16 : fullWidth ? 12 : 7}
                    >
                        {!isTabletPortraitOrBelow && <h1 className="bold upper">{title}</h1>}
                        {children}
                    </Grid.Column>
                </Grid>
            </div>
        </Layout>
    );
};

export default withLocation(AccountPageWrap);
